.service-main {
  padding-top: 40px;
  padding-bottom: 60px;
}
.top-icon {
  display: flex;
  column-gap: 30px;
  justify-content: center;
  align-items: center;
}
.top-icon .hr-line {
  width: 100px;
  height: 5px;
  background-color: var(--primary-green);
}
.top-icon .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: var(--primary-green);
}

.service-content {
  margin-top: 40px;
}
.service-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.service-text h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--primary-green);
}
.service-text p {
  font-size: 18px;
  color: var(--black);
  line-height: 30px;
  width: 800px;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-text h1 {
    font-size: 40px;
  }
  .service-text p {
    font-size: 16px;
    width: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .service-text h1 {
    font-size: 30px;
  }
  .service-text p {
    font-size: 16px;
    width: auto;
  }
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 479px) {
}

@media only screen and (max-width: 390px) {
}
