.blog-detail-img{
    height:auto;
    width:80%;
}
.blog-detail-img img{
    width:100%;
    height:auto;
    object-fit: cover;
}

@media  screen and (max-width:900px) {
    .blog-detail-img{
        height:auto;
        width:100%;
    }
    
    .blog-detail-img img{
        width:100%;
        height:auto;
        object-fit: cover;
    } 
}

