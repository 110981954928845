.about-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--white);
}
.about-para {
  font-size: 18px;
  color: var(--white);
  opacity: 80%;
  line-height: 30px;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-title {
    font-size: 40px;
  }
  .about-para {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .about-title {
    font-size: 35px;
  }
  .about-para {
    font-size: 16px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .about-title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .about-main{
    margin-top: 20px;
  }
  .about-main .text-content{
    margin-top: -200px;
  }
  .about-main .about-para{
    font-size: 14px;
  }
}
