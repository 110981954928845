
.about-page .mission .mission-card .card{
    width:18rem;
}

@media only screen and (max-width: 426px) {
    
    .about-page .who-we-are h1{
        text-align: center;
    }
    .about-page .who-we-are p{
        font-size: 16px;
        text-align: center;
    }
    .about-page .text-1{
       padding-right: 10px;
    }

    .about-page .vision h1{
        text-align: center;
    }
    .about-page .vision .text-1 p{
        font-size: 16px;
        text-align: center;

    } 

    .about-page .mission .text-1 p{
        font-size: 16px;

    }
    .about-page .mission .mission-card .card{
        width:100%;
    }
    .about-page{
        margin-bottom: 150px;
    }
  }