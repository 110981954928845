.guarantee-content .guarantee-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.guarantee-content .guarantee-text {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  background-color: var(--primary-green);
}

.guarantee-content .guarantee-icon img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
