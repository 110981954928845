.image-gallery-container {
  overflow: hidden;
}

.image-gallery {
  display: flex;
  animation: scrollGallery 20s linear infinite;
}

@keyframes scrollGallery {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.image-item img {
  width: 290px;
  height: 200px;
  object-fit: cover;
}
